import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    categorias: []
  },
  mutations: {
    set_categorias (state, payload) {
      state.categorias = payload
      order_list_by(state.categorias, 'nombre')
      localStorage.setItem('categorias', JSON.stringify(payload))
    },
    add_categoria(state, payload){
      state.categorias.push(payload);
    }
  },
  actions: {
    // payload = codigo de la categoria
    async inhabilitar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/inhabilitarCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: payload })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = codigo de la categoria
    async habilitar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/habilitarCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: payload })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = codigo (de la categoria), nombre, rubro_codigo, tipo_fe_codigo, solicita_fe_aut, no_paga_desc_empl, solicita_serie, actualiza_precio_may
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/editarCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = nombre, rubro_codigo, tipo_fe_codigo, solicita_fe_aut, no_paga_desc_empl, solicita_serie, actualiza_precio_may
    async nueva ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/nuevaCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = filtro
    async get_categorias ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/getCategorias`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene las categorias habilitadas y las guarda en el localStorage para utilizarlas en otros formularios
    async get_categorias_habilitadas ({ dispatch, commit, state }) {
      if (state.categorias.length == 0) {
        await dispatch('get_categorias', { inhabilitado: 0 })
          .then((res) => {
            commit('set_categorias', res.data)
          })
          .catch((error) => {
            commit('set_categorias', [])
            store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    add_categoria({commit, state}, payload){
      commit('add_categoria', payload);
      commit('set_categorias', state.categorias);
    }
  }
}