import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initPromoIndex({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminPromociones/initFormIndex`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initPromoIndex: ' + error.message
          })
        }
      })
    },
    async getPromociones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let promosPeticion = await fetch(`${config.BASE_URL}/adminPromociones/getPromociones`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let promos = await promosPeticion.json()
          promos.msj = promos.message
          return resolve(promos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getPromociones: ' + error.message
          })
        }
      })
    },
    async getCuponesPromocion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cuponesPeticion = await fetch(`${config.BASE_URL}/adminPromociones/getCuponesPromocion?promocion_id=${datos.promocion_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let cupones = await cuponesPeticion.json()
          cupones.msj = cupones.message
          return resolve(cupones)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getCuponesPromocion: ' + error.message
          })
        }
      })
    },
    async generarCupones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let generarPeticion = await fetch(`${config.BASE_URL}/adminPromociones/generarCuponesPromocion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let generar = await generarPeticion.json()
          generar.msj = generar.message
          return resolve(generar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo generarCupones: ' + error.message
          })
        }
      })
    },
    async initFormUnaPromo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminPromociones/initFormUnaPromo?nuevo=${datos.nuevo}&promocion_id=${datos.promocion_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initFormUnaPromo: ' + error.message
          })
        }
      })
    },
    async getAllArticulos({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let artsPeticion = await fetch(`${config.BASE_URL}/adminPromociones/getAllArticulos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let arts = await artsPeticion.json()
          arts.msj = arts.message
          return resolve(arts)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getAllArticulos: ' + error.message
          })
        }
      })
    },
    async sendPromocion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let nuevaPeticion = await fetch(`${config.BASE_URL}/adminPromociones/sendPromocion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nueva = await nuevaPeticion.json()
          nueva.msj = nueva.message
          return resolve(nueva)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo sendPromocion: ' + error.message
          })
        }
      })
    },
    async updatePromocion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/adminPromociones/updatePromocion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updatePromocion: ' + error.message
          })
        }
      })
    },
    async generarPromosFile({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let generarPeticion = await fetch(`${config.BASE_URL}/adminPromociones/generarPromosFile`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let generar = await generarPeticion.json()
          generar.msj = generar.message
          return resolve(generar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo generarPromosFile: ' + error.message
          })
        }
      })
    },
    async getCliente({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminPromociones/getCliente?documento=${payload.documento}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener datos del cliente: " + error.message
          })
        }
      })
    },
    async getDescuentosCliente({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminPromociones/getDescuentosCliente?documento=${payload.documento}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener datos del cliente: " + error.message
          })
        }
      })
    },
    //getArticuloPorCodigo
    async obtenerArticuloPorCodigo({}, payload){ 
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminPromociones/getArticuloPorCodigo?articulo_codigo=${payload.articulo_codigo}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener el articulo: " + error.message
          })
        }
      })
    },
    async obtenerArticulos({}, payload){ 
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminPromociones/getArticulos`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener los articulos: " + error.message
          })
        }
      })
    },
    async nuevoDescuentoEspecial({}, payload){
      return await new Promise(async (resolve, reject) => {
        try{
          let res = await fetch(`${config.BASE_URL}/adminPromociones/nuevoDescuentoEspecial`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          let data = await res.json()
          resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al grabar nuevo descuento especial: ' + error.message
          })
        }
      })
    },
    async inhabilitarDescuentoEspecial({}, payload){
      return await new Promise(async (resolve, reject) => {
        try{
          let res = await fetch(`${config.BASE_URL}/adminPromociones/inhabilitarDescuentoEspecial`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          let data = await res.json()
          resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al inhabilitar descuento especial: ' + error.message
          })
        }
      })
    },
  }
}