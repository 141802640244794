import config from "../../../config"
import store from "../../store"

export default {
  namespaced: true,
  state:{

  },
  mutations:{

  },
  actions:{
    //Iniciar el Formulario
    async initMayoristaCobranza({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/initCobranzaMayorista?nuevo=${payload.nuevo}&cobranza_id=${payload.cobranza_id}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          return resolve(data)

          // if(data.resultado === 1){
          //   resolve(data);
          // }else{
          //   reject(data);
          // }
        } catch (error) {
          // reject(error)
          return resolve({
            resultado: 0,
            message: 'Ocurrió un problema al ejecutar el método initMayoristaCobranza: ' + error.message
          })
        }
      })
    },
    //Buscar cobranzas
    async buscarCobranzas({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/getCobranzas?vendedor_codigo=${payload.vendedor_codigo}&cliente_nombre=${payload.cliente_nombre}&cliente_codigo=${payload.cliente_codigo}&sucursal_codigo=${payload.sucursal_codigo}&pto_vta_codigo=${payload.pto_vta_codigo}&fecha_desde=${payload.fecha_desde}&fecha_hasta=${payload.fecha_hasta}&cobranza_id=${payload.cobranza_id}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();

          if(data.resultado === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Nuevo Cobranza
    async nuevoCobranza({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/saveCobranzaMayorista`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Generar el PDF de Recibo cobranza
    async generarPdfCobranza({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/getReciboData?cobranza_id=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          return resolve(data)
        } catch (error) {
          return resolve({
            resultado: 0,
            message: 'Ocurrió un problema al ejecutar el método generarPdfCobranza: ' + error.message
          })
        }
      })
    },
    //Obtener un Cliente Mayorista
    async obtenerUnClienteMayorista({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/getClienteMayorista?cliente_codigo=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          //console.log("data: ", data);
          if(data.resultado === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Obtener Clientes Mayoristas
    async obtenerClientesMayoristas({}, payload){
      //{ejecutivo_codigo: this.ejecutivo_codigo, codigo: 0, nombre: nombre, nroDocumento: documento} 9291
      return await new Promise(async (resolve, reject) => {
        try { 
           const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/getClientesMayoristas?ejecutivo_codigo=${payload.ejecutivo_codigo}&vendedor_codigo=${payload.codigo}&nombre=${payload.nombre}&documento=${payload.nroDocumento}`,{
             method: 'GET',
             headers: {
               'Content-Type': 'application/json',
               'Authorization': store.state.long_token
             }
           })
          const data = await res.json();
          /*console.log("data: ", data);
          return*/
          if(data.resultado === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Obtener saldos a Favor del Cliente
    async obtenerSaldosAfavor({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/getSaldosFavorCliente?cliente_codigo=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          //console.log("data: ", data);
          if(data.resultado === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Anular Cobranza
    async anularCobranza({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/anularCobranza`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token,
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          //console.log("data: ", data);
          if(data.resultado === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Obtener vendedores por Pto. de Ventas
    async obtenerVendedoresPtoVta({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminCobranzasMayoristas/getVendedoresXPtoVta?pto_vta_codigo=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          //console.log("data: ", data);
          if(data.resultado === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
  }
}