import config from "../../../config"
import store from "../../store"

export default {
  namespaced: true,
  state: {
    p_lim_cred: false,
    p_eliminar: false,
    p_editar: false,
    p_descargar: 0,
  },
  mutations: {
    set_permisos (state, payload) {
      state.p_lim_cred = payload.p_lim_cred,
      state.p_eliminar = payload.p_eliminar,
      state.p_editar = payload.p_editar
      state.p_descargar = payload.p_descargar;
    }
  },
  actions: {
    async editar_limcred ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/editarLimCred`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async habilitar ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/habilitarGestor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async inhabilitar ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/inhabilitarGestor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async validar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/validar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            error: 'Jarvis 2 error',
            message: error.message
          })
        }
      })
    },
    async nuevo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/nuevoGestor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/editarGestor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_gestores ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/gestores/getGestores`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_permisos ({ commit }) {
      const permisos_false = {
        p_lim_cred: false,
        p_eliminar: false,
        p_editar: false,
        p_descargar: false
      }
      try {
        const res = await fetch(`${config.BASE_URL}/gestores/permiso`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
    
        const data = await res.json()
        if (data.exito == 1) {
          commit('set_permisos', {
            p_lim_cred: data.permisos.lim_cred == 1 ? true : false,
            p_eliminar: data.permisos.eliminar == 1 ? true : false,
            p_editar: data.permisos.editar == 1 ? true : false,
            p_descargar: data.permisos.descargar == 1 ? true : false
          })
        }else {
          commit('set_permisos', permisos_false)
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
    
      } catch (error) {
        commit('set_permisos', permisos_false)
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}